import React, { useMemo, useEffect, useState } from "react";
import MaterialReactTable from "material-react-table";
import { extractEmailsFromString } from "../../services/helper";
import SchedulerTag from "./SchedulerTag";
import useGlobalStore from "../../store";
import { shallow } from "zustand/shallow";
import { Space, Tag, Button, notification, Tooltip, Modal } from "antd";
import { SaveOutlined, StopOutlined } from "@ant-design/icons";
import DetailsModal from "./DetailsModal";
import _ from "lodash";

function _SchedulerTable({ tableRef, data, isLoading = false }) {
  const columns = useMemo(
    () => [
      {
        accessorKey: "wave_name", //access nested data with dot notation
        header: "WEEK",
        size: 30,

        // GroupedCell: ({cell,row})=>{}
        //
      },
      {
        accessorKey: "migtype",
        header: "MIG TYPE",
        size: 30,
      },
      {
        accessorKey: "servername", //normal accessorKey
        header: "SERVER",
        size: 30,
      },
      {
        accessorKey: "appname",
        header: "APP CI",
        size: 30,
      },
      {
        accessorKey: "app_project_name", //normal accessorKey
        header: "APP PROJECT",
        maxSize: 20,
      },
      {
        accessorKey: "env",
        header: "ENV",
        size: 30,
      },
      {
        accessorKey: "migration_date",
        header: "MDATE",
        size: 30,
      },
      {
        accessorKey: "downtime",
        header: "HRS",
        size: 30,
      },
      {
        accessorKey: "migration_time",
        header: "MTIME",
        size: 30,
      },
      {
        accessorKey: "app_managed_by",
        header: "MANAGED BY",
        size: 30,
      },
      {
        accessorKey: "ipchange",
        header: <Tooltip title="IP CHANGE">IP</Tooltip>,
        size: 30,
      },
      {
        accessorKey: "gxp",
        header: <Tooltip title="GXP">GXP</Tooltip>,
        size: 30,
      },
      {
        accessorKey: "approval_status",
        header: <Tooltip title="APPROVAL STATUS">STATUS</Tooltip>,
        size: 30,
      },
      {
        accessorKey: ['approvers', 'testers'],
        header: "APPROVAL",
        Cell: ({ renderedCellValue, row }) => {
          return <Button onClick={() => Modal.info({
            content: React.createElement(DetailsModal, { row }),
            title: 'Approvals, Approvers and Testers details',
            closable: true,
            style: { minWidth: '75vw' },
            okButtonProps: { type: 'default' },
            okText: 'Close'
          })} type='default'> Details </Button>
        }
      },

      // {
      //   accessorKey: "approved_by",
      //   header: "APPROVED BY",
      //   size: 30,
      //   Cell: ({ renderedCellValue, row }) => {
      //     let isids_approvers = typeof renderedCellValue === "string" ? renderedCellValue.split(";") : [];
      //     let isid_approvers_data = [];
      //     if (isids_approvers.length == 0) {
      //       return "-";
      //     }
      //     isids_approvers = isids_approvers.filter((item) => item && item !== "");

      //     for (let i = 0; i < isids_approvers.length; i++) {
      //       if (validator.isEmail(isids_approvers[i].trim())) {
      //         isid_approvers_data.push(
      //           <Tag key={isids_approvers[i] + row.original.app_ci_id}>{isids_approvers[i]}</Tag>
      //         );
      //         continue;
      //       } else {
      //         isid_approvers_data.push(
      //           <SchedulerTag
      //             type="APPROVER"
      //             tag_search_key={isids_approvers[i]}
      //             tag_key={isids_approvers[i] + row.original.app_ci_id}
      //             closable={false}
      //           />
      //         );
      //       }
      //     }

      //     return isid_approvers_data;
      //   },
      // },
      // {
      //   accessorKey: "approval_date",
      //   header: "APPROVAL DATE",
      //   size: 30,
      // },
      // {
      //   accessorKey: "approvers",
      //   header: "APPROVERS",
      //   size: 30,
      //   Cell: ({ renderedCellValue, row }) => {
      //     let isids_approvers = typeof renderedCellValue === "string" ? renderedCellValue.split(";") : [];
      //     let isid_approvers_data = [];
      //     if (isids_approvers.length == 0) {
      //       return "-";
      //     }
      //     let app_managed_by = isids_approvers[0];
      //     isids_approvers = isids_approvers.filter((item) => item && item !== "");

      //     for (let i = 0; i < isids_approvers.length; i++) {
      //       if (validator.isEmail(isids_approvers[i].trim())) {
      //         isid_approvers_data.push(
      //           <Tag
      //             key={isids_approvers[i] + row.original.app_ci_id}
      //             closable={isids_approvers[i] !== app_managed_by}
      //             onClose={() =>
      //               delete_approvers_testers_api(
      //                 "APPROVER",
      //                 row.original?.migtype,
      //                 row.original?.sched_id,
      //                 isids_approvers[i],
      //                 true
      //               )
      //             }
      //           >
      //             {isids_approvers[i]}
      //           </Tag>
      //         );
      //         continue;
      //       } else {
      //         isid_approvers_data.push(
      //           <SchedulerTag
      //             type="APPROVER"
      //             tag_search_key={isids_approvers[i]}
      //             tag_key={isids_approvers[i] + row.original.app_ci_id}
      //             closable={isids_approvers[i] !== app_managed_by}
      //             onClose={() =>
      //               delete_approvers_testers_api(
      //                 "APPROVER",
      //                 row.original?.migtype,
      //                 row.original?.sched_id,
      //                 isids_approvers[i],
      //                 true
      //               )
      //             }
      //           />
      //         );
      //       }
      //     }

      //     return isid_approvers_data;
      //   },
      // },
      // {
      //   accessorKey: "testers",
      //   header: "TESTERS",
      //   size: 30,
      //   Cell: ({ renderedCellValue, row }) => {
      //     let isids_testers = typeof renderedCellValue === "string" ? renderedCellValue.split(";") : [];
      //     let isid_testers_data = [];
      //     if (isids_testers.length == 0) {
      //       return "-";
      //     }
      //     for (let i = 0; i < isids_testers.length; i++) {
      //       if (validator.isEmail(isids_testers[i])) {
      //         isid_testers_data.push(
      //           <SchedulerTag
      //             type="TESTER"
      //             tag_search_key={isids_testers[i]}
      //             tag_key={isids_testers[i] + row.original.app_ci_id}
      //             tag_value={renderedCellValue}
      //             closable={true}
      //             onClose={() =>
      //               delete_approvers_testers_api(
      //                 "TESTER",
      //                 row.original?.migtype,
      //                 row.original?.sched_id,
      //                 renderedCellValue,
      //                 true
      //               )
      //             }
      //           />
      //         );
      //         continue;
      //       } else {
      //         isid_testers_data.push(
      //           <SchedulerTag
      //             type="TESTER"
      //             tag_search_key={isids_testers[i]}
      //             tag_key={isids_testers[i] + row.original.app_ci_id}
      //             closable={true}
      //             onClose={() =>
      //               delete_approvers_testers_api(
      //                 "TESTER",
      //                 row.original?.migtype,
      //                 row.original?.sched_id,
      //                 renderedCellValue,
      //                 true
      //               )
      //             }
      //           />
      //         );
      //       }
      //     }

      //     return isid_testers_data;
      //   },
      // },
      {
        accessorKey: "schedule_status",
        header: "SCHED STATUS",
        size: 30,
      },
      {
        accessorKey: "divisional_lead",
        header: "DIV LEAD",
        size: 30,
        Cell: ({ renderedCellValue, row }) => {
          let emails_divlead = extractEmailsFromString(renderedCellValue);
          let divisional_lead_data = [];
          for (let i = 0; i < emails_divlead.length; i++) {
            divisional_lead_data.push(
              <SchedulerTag
                type="DIVLEAD"
                tag_search_key={emails_divlead[i]}
                tag_key={emails_divlead[i] + row.original.app_ci_id}
              />
            );
          }
          return divisional_lead_data;
        },
      },

      {
        accessorKey: "product_manager",
        header: "PROD MANAGER",
        size: 30,
      },
      {
        accessorKey: "division",
        header: <Tooltip title="Division">DIV</Tooltip>,
        size: 30,
      },
      {
        accessorKey: "supported_by",
        header: "SUPPORTED BY",
        size: 30,
      },
    ],
    []
  );
  const [rowSelection, setRowSelection] = useState({});
  const { delete_approvers_testers_api, set_selected_rows_scheduler } = useGlobalStore(
    (state) => ({
      delete_approvers_testers_api: state.delete_approvers_testers_api,
      set_selected_rows_scheduler: state.set_selected_rows_scheduler,
    }),
    shallow
  );

  useEffect(() => {
    set_selected_rows_scheduler(tableRef);
  }, [rowSelection]);

  const handleRowSelection = (rows) => {
    let _rows = rows(rowSelection)
    _rows = _.omitBy(_rows, (value, key) => _.isNaN(+key))
    _rows = { ..._rows, ...getParentRowsToMarkSelected(_rows) }
    setRowSelection(_rows);
  };

  const getParentRowsToMarkSelected = (_rows) => {
    let parent_rows = {}
    let grouping = tableRef.current.getState().grouping
    let grouping_len = grouping.length

    let key = ''
    let _row = ''
    if (grouping?.length > 0) {
      Object.keys(_rows).forEach((row_id) => {
        if (row_id) {
          key = ''
          _row = tableRef.current.getRow(row_id).original
          if (_row) {
            grouping.forEach((group, idx) => {
              key += `${group}:${_row?.[group]}`
              if (idx !== grouping_len - 1) {
                parent_rows[key] = true
                key += ">"
              }
            })
            parent_rows[key] = true
          }

        }
      })
    }
    return parent_rows
  }

  const setPagination = (old, func) => { };
  const saveTableStyle = () => {
    window.localStorage.setItem("scheduler_table_state", JSON.stringify(tableRef.current.getState()));
    notification.success({ message: "Table state saved." });
  };
  const clearTableStyle = () => {
    window.localStorage.removeItem("scheduler_table_state");
    window.location.reload()
  };

  const getSavedTableState = () => {
    let common = {
      grouping: ["wave_name", "migtype"],
      pagination: { pageSize: 100, pageIndex: 0 },
      density: "compact",
    }
    let saved_state = window.localStorage.getItem("scheduler_table_state");
    if (saved_state) {
      return { ...common, ...JSON.parse(saved_state) }
    } else {
      return common;
    }
  };




  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={data}
        state={{ isLoading, rowSelection }}
        enableRowSelection
        enableColumnResizing
        enableColumnOrdering
        enableColumnDragging
        muiTableProps={{
          sx: {
            tableLayout: "auto",
          },
        }}
        enableStickyHeader
        initialState={getSavedTableState()}
        enableGrouping
        enablePagination
        muiTablePaginationProps={{
          showFirstButton: true,
          showLastButton: true,
          rowsPerPageOptions: [25, 50, 100, 250, 500, 1000],
        }}
        enableGlobalFilterModes
        tableInstanceRef={tableRef}
        enableExpandAll
        onRowSelectionChange={handleRowSelection}
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <Space>
              <Button type="default" icon={<SaveOutlined />} size={"small"} onClick={saveTableStyle}>
                Save Table State
              </Button>
              <Button type="default" icon={<StopOutlined />} size={"small"} onClick={clearTableStyle}>
                Clear Table State
              </Button>
            </Space>
          );
        }}
      // onPaginationChange={setPagination}
      // muiTableBodyRowProps={({isDetailPanel,row,table})=>{
      //   console.log(row.original)
      // }}
      />
    </div>
  );
}

export default React.memo(_SchedulerTable);
