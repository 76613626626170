import { blue, green, red, orange, yellow, gray } from '@ant-design/colors';

export const STATUS_TYPES = {
    'INITIAL': 'Initial',
    'VIEWED': 'Viewed',
    'IN_PROGRESS': 'In Progress',
    'SUBMITTED': 'Submitted',
    'NEED_RESPONSE': 'Needs Response',
    'COMPLETE': 'Complete'
}

export const STATUS_TYPES_META = {
    'Initial': {
        color: "default"
    },
    'Viewed': {
        color: gray[5]
    },
    'In Progress': {
        color: yellow[6]
    },
    'Submitted': {
        color: orange[1]
    },
    'Needs Response': {
        color: red[5]
    },
    'Complete': {
        color:  blue[5]
    }
}