import { useRef, useState, useEffect } from 'react';
import DropdownButton from "react-bootstrap/DropdownButton";
import "react-calendar/dist/Calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-tabs/style/react-tabs.css';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice he
import DownloadWaveStatus from "../TableActions/DownloadWaveStatus";
import * as storage from '../../services/migserv_storage';
import SchedulerTable from "./SchedulerTable";
import moment from 'moment'
import { getListOfMerckWeeksFromAGivenWeek } from "../../services/helper";
import ApproverTester from "../TableActions/ApproverTester";
import useGlobalStore from "../../store";
import { shallow } from "zustand/shallow";
import { notification } from "antd"
import * as _ from 'lodash'
import appAxios from '../app_config/axios';
import CommsEmail from '../TableActions/CommsEmail';
import ApprovalsUpdate from 'components/TableActions/ApprovalsUpdate';

export const SchedulerAdmin = ({ userID }) => {
  const tableRef = useRef(null)
  const newSelectRow = useRef(null)

  var currentWeek = moment().format('YY') + 'W' + moment().format('WW')

  const [appData, setData] = useState([]);
  const [tableData, setTableData] = useState([])
  const [isLoading, setLoading] = useState(true);
  const [isTableDataLoading, setIsTableDataLoading] = useState(true)
  const [isChecked, setIsChecked] = useState('needs attention');
  let isid = storage.get('user.isid', '');



  const { _approvers_testers_api$,
    selected_rows_scheduler } = useGlobalStore((state) => ({
      _approvers_testers_api$: state._approvers_testers_api$,
      selected_rows_scheduler: state.selected_rows_scheduler
    }), shallow)

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(csvOptions);


  useEffect(
    () => {
      (async function () {
        await fetch_data()
      })();
    }, []);

  useEffect(() => {
    if (_approvers_testers_api$) {
      fetch_data()
    }
  }, [_approvers_testers_api$])

  useEffect(() => {
    (async () => {
      search(appData)
    })()
  }, [isChecked])

  function HandleReset() {
    if (tableRef.current != null) {
      tableRef.current.reset()
    }
  }

  const fetch_data = async () => {
    if (isid)
      userID = isid
    let url = process.env.REACT_APP_MIGSERV_API_URL ? process.env.REACT_APP_MIGSERV_API_URL + '/migrating_applications' : ''
    let result = await appAxios.get(url
      , {
        params: {
          username: userID
        }
      });
    if (Array.isArray(result.data)) {
      result.data.sort(function (a, b) {
        return a?.migration_date?.localeCompare(b?.migration_date);
      });
    } else {
      result.data = []
    }
    setData(result?.data ? result.data : []);
    search(result.data)
    setLoading(false)
  }


  const search = async (rows = []) => {
    setIsTableDataLoading(true)
    setTableData([])
    let filteredRows = []
    if (['needs attention', 'migrating', 'all'].includes(isChecked)) {
      if (tableRef?.current) {
        tableRef.current.resetExpanded()
      }
    }
    if (isChecked == "needs attention") {
      let weeks = [currentWeek, ...getListOfMerckWeeksFromAGivenWeek(currentWeek, 8)]
      filteredRows = rows.filter((row) => ((row?.approved_by == "" || !row?.approved_by) && (row?.approval_date == "" || !row?.approval_date) && weeks.includes(row?.wave_name)));
    } else if (isChecked == "migrating") {
      filteredRows = rows.filter((row) => row?.approval_status === 'SCHEDULED')
    } else if (isChecked == "all") {
      setIsTableDataLoading(false)
      setTableData(_.sortBy(rows, (item) => parseInt(item.wave_name.substr(3))))
      return
    } else if (isChecked == "this_week") {
      if (!tableRef.current.getIsAllRowsExpanded()) {
        tableRef.current.toggleAllRowsExpanded()
      }
      filteredRows = rows.filter((row) => row?.wave_name == currentWeek)
    } else {
      return rows
    }
    setIsTableDataLoading(false)
    setTableData(_.sortBy(filteredRows, (item) => parseInt(item.wave_name.substr(3))))
  }

  if (isLoading) {
    return <div style={{ width: "96%", margin: " 40px auto" }} className="Loading">Loading...</div>;
  }

  const exportTableData = () => {
    if (selected_rows_scheduler.length == 0) {
      notification.warning({ message: 'Please select the rows to export' })
    } else {
      csvExporter.generateCsv(selected_rows_scheduler)
    }
  }

  console.log("tableData",tableData);

  return (
    <div style={{ width: "96%", margin: 'auto' }}>
      <div className="mx-auto py-3 px-4 flex justify-between border border-black">
        <div className="bulits">
          <label className="bulits">
            <input
              type="radio"
              name="filter"
              value="needs attention"
              defaultChecked
              onChange={e => setIsChecked(e.target.value)}
              className="outline-none bg-white rounded-md py-2 px-3 border border-black"
            /> NEED APPROVAL
          </label>

          <label className="bulits">
            <input
              type="radio"
              name="filter"
              value="this_week"
              onChange={e => setIsChecked(e.target.value)}
              className="outline-none bg-white rounded-md py-2 px-3 border border-black"
            /> THIS WEEK
          </label>
          <label className="bulits">
            <input
              type="radio"
              name="filter"
              value="all"
              onChange={e => setIsChecked(e.target.value)}
              className="outline-none bg-white rounded-md py-2 px-3 border border-black"
            /> ALL MIGRATIONS
          </label>
        </div>
        <ApprovalsUpdate />
        <DropdownButton variant="contained" className="bannerBg" size="lg" id="dropdown-item-button" title="Select an Action" drop="down">
          <button className='btns' as="button" onClick={exportTableData} > Export Data</button>
          <DownloadWaveStatus rowInstance={newSelectRow} tableRef={tableRef} />
          <ApproverTester tableRef={tableRef} manage_type='APPROVER' />
          <ApproverTester tableRef={tableRef} manage_type='TESTER' />
          <CommsEmail tableRef={tableRef} />
        </DropdownButton>
      </div>


      <SchedulerTable
        isLoading={isTableDataLoading}
        data={tableData}
        tableRef={tableRef}
      />

    </div >
  );


};



export default SchedulerAdmin;